import fetch from 'auth/FetchInterceptor';

const LoadDisputeService = {};

LoadDisputeService.getAll = function () {
  return fetch({
    url: 'dispute_loads',
    method: 'get',
  });
};

LoadDisputeService.details = function (id) {
  return fetch({
    url: `dispute_loads/${id}`,
    method: 'get',
  });
};

LoadDisputeService.update = function (data) {
  return fetch({
    url: `dispute_loads/${data.dispute_id}`,
    method: 'patch',
    data: data,
  });
};

LoadDisputeService.create = function (data) {
  return fetch({
    url: `dispute_loads`,
    method: 'post',
    data: data,
  });
};

LoadDisputeService.detailsForTrip = function (trip_id) {
  return fetch({
    url: `dispute_loads/trip/${trip_id}`,
    method: 'get',
  });
};

LoadDisputeService.delete = function (id) {
  return fetch({
    url: `dispute_loads/${id}`,
    method: 'delete',
  });
};

export default LoadDisputeService;
