import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_TOKEN } from "constants/AuthConstant";
import BodyTypeService from "services/BodyTypeService";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  token: localStorage.getItem(AUTH_TOKEN) || null,
  data: null,
};

export const getAllBodyType = createAsyncThunk(
  "bodyType/getAll",
  async (state_id, { rejectWithValue }) => {
    try {
      const response = await BodyTypeService.getAll(state_id);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const bodyTypeSlice = createSlice({
  name: "bodyType",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    getAllBodyTypeSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBodyType.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllBodyType.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getAllBodyType.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.data = null;
      });
  },
});

export const { getAllBodyTypeSuccess, showLoading } = bodyTypeSlice.actions;

export default bodyTypeSlice.reducer;
