import fetch from 'auth/FetchInterceptor';

const CityService = {};

CityService.getAll = function (params) {
  console.log('params', params);
  return fetch({
    url: params.state_id
      ? `cities?size=${params?.perPage}&page=${params.currPage}&state_id=${params.state_id}&search=${params.search}`
      : `cities?size=${params?.perPage}&page=${params.currPage}&search=${params.search}`,
    method: 'get',
  });
};
export default CityService;
