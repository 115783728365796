import fetch from "auth/FetchInterceptor";

const StateService = {};

StateService.getAll = function () {
  return fetch({
    url: "states",
    method: "get",
  });
};

export default StateService;
