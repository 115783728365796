import fetch from 'auth/FetchInterceptor';

const LoadAdvanceService = {};

LoadAdvanceService.getAll = function () {
  return fetch({
    url: 'advance_loads',
    method: 'get',
  });
};

LoadAdvanceService.details = function (id) {
  return fetch({
    url: `advance_loads/${id}`,
    method: 'get',
  });
};

LoadAdvanceService.update = function (data) {
  return fetch({
    url: `advance_loads/${data.broker_id}`,
    method: 'patch',
    data: data,
  });
};

LoadAdvanceService.create = function (data) {
  return fetch({
    url: `advance_loads`,
    method: 'post',
    data: data,
  });
};

LoadAdvanceService.detailsForTrip = function (trip_id) {
  return fetch({
    url: `advance_loads/trip/${trip_id}`,
    method: 'get',
  });
};

LoadAdvanceService.delete = function (id) {
  return fetch({
    url: `advance_loads/${id}`,
    method: 'delete',
  });
};

export default LoadAdvanceService;
