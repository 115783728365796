import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import OwnerService from 'services/OwnerService';

export const initialState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  token: localStorage.getItem(AUTH_TOKEN) || null,
  data: null,
  details: null,
};

export const getAllOwner = createAsyncThunk(
  'owner/getAll',
  async (params, { rejectWithValue }) => {
    try {
      const response = await OwnerService.getAll(params);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const getOwnerDetails = createAsyncThunk(
  'broker/getDetails',
  async (id, { rejectWithValue }) => {
    try {
      const response = await OwnerService.details(id);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const updateOwnerDetails = createAsyncThunk(
  'broker/updateDetails',
  async (data, { rejectWithValue }) => {
    try {
      const response = await OwnerService.update(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const ownerSlice = createSlice({
  name: 'owner',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    getAllOwnerSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllOwner.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllOwner.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getAllOwner.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.data = null;
      })
      .addCase(getOwnerDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOwnerDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.details = action.payload;
      })
      .addCase(getOwnerDetails.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.details = null;
      })
      .addCase(updateOwnerDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateOwnerDetails.fulfilled, (state, action) => {
        state.loading = false;
        // state.details = action.payload;
      })
      .addCase(updateOwnerDetails.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        // state.details = null;
      });
  },
});

export const { getAllOwnerSuccess, showLoading } = ownerSlice.actions;

export default ownerSlice.reducer;
