import { combineReducers } from 'redux';
import theme from './slices/themeSlice';
import auth from './slices/authSlice';
import vehicle from './slices/vehicleSlice';
import owner from './slices/ownerSlice';
import broker from './slices/brokerSlice';
import destination from './slices/destinationSlice';
import documents from './slices/documentsSlice';
import account from './slices/accountsSlice';
import states from './slices/stateSlice';
import city from './slices/citySlice';
import bodyType from './slices/bodyTypeSlice';
import rto from './slices/rtoSlice';
import vehicleLength from './slices/vehicleLengthSlice';
import driver from './slices/driverSlice';
import load from './slices/loadSlice';
import customer from './slices/customerSlice';
import trip from './slices/tripSlice';
import advance from './slices/advanceSlice';
import loadAdvance from './slices/loadAdvanceSlice';
import dispute from './slices/disputeSlice';
import loadDispute from './slices/loadDisputeSlice';

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    vehicle,
    owner,
    broker,
    destination,
    documents,
    account,
    states,
    city,
    bodyType,
    rto,
    vehicleLength,
    driver,
    load,
    customer,
    trip,
    advance,
    loadAdvance,
    dispute,
    loadDispute,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
