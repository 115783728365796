import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import TripService from 'services/TripService';

export const initialState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  token: localStorage.getItem(AUTH_TOKEN) || null,
  data: null,
  details: null,
  tripStatus: null,
};

export const getAllTrips = createAsyncThunk(
  'trip/getAll',
  async (params, { rejectWithValue }) => {
    try {
      const response = await TripService.getAll(params);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const getTripDetails = createAsyncThunk(
  'trip/getDetails',
  async (id, { rejectWithValue }) => {
    try {
      const response = await TripService.details(id);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const updateTripDetails = createAsyncThunk(
  'trip/updateDetails',
  async (data, { rejectWithValue }) => {
    try {
      const response = await TripService.update(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const addTrip = createAsyncThunk(
  'trip/add',
  async (data, { rejectWithValue }) => {
    try {
      const response = await TripService.create(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const getStatus = createAsyncThunk(
  'trip/getStatus',
  async (tripId, { rejectWithValue }) => {
    try {
      const response = await TripService.getStatus(tripId);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const tripSlice = createSlice({
  name: 'trip',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    getAllTripSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTrips.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllTrips.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getAllTrips.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.data = null;
      })
      .addCase(getTripDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTripDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.details = action.payload;
      })
      .addCase(getTripDetails.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.details = null;
      })
      .addCase(updateTripDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateTripDetails.fulfilled, (state, action) => {
        state.loading = false;
        // state.details = action.payload;
      })
      .addCase(updateTripDetails.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        // state.details = null;
      })
      .addCase(addTrip.pending, (state) => {
        state.loading = true;
      })
      .addCase(addTrip.fulfilled, (state, action) => {
        state.loading = false;
        // state.details = action.payload;
      })
      .addCase(addTrip.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        // state.details = null;
      })
      .addCase(getStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.tripStatus = action.payload;
      })
      .addCase(getStatus.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        // state.details = null;
      });
  },
});

export const { getAllTripSuccess, showLoading } = tripSlice.actions;

export default tripSlice.reducer;
