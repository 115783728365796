import fetch from "auth/FetchInterceptor";

const DestinationService = {};

DestinationService.getAll = function (data) {
  return fetch({
    url: "vehicles",
    method: "get",
  });
};

DestinationService.details = function (id) {
  return fetch({
    url: `destinations/vehicle/${id}`,
    method: "get",
  });
};

DestinationService.update = function (data) {
  return fetch({
    url: "destinationspatch/bulk1",
    method: "patch",
    data: data,
  });
};
export default DestinationService;
