import fetch from "auth/FetchInterceptor";

const AdvanceService = {};

AdvanceService.getAll = function () {
  return fetch({
    url: "advances",
    method: "get",
  });
};

AdvanceService.details = function (id) {
  return fetch({
    url: `advances/${id}`,
    method: "get",
  });
};

AdvanceService.update = function (data) {
  return fetch({
    url: `advances/${data.broker_id}`,
    method: "patch",
    data: data,
  });
};

AdvanceService.create = function (data) {
  return fetch({
    url: `advances`,
    method: "post",
    data: data,
  });
};

AdvanceService.detailsForTrip = function (trip_id) {
  return fetch({
    url: `advances/trip/${trip_id}`,
    method: "get",
  });
};

AdvanceService.delete = function (id) {
  return fetch({
    url: `advances/${id}`,
    method: "delete",
  });
};

export default AdvanceService;
