import fetch from 'auth/FetchInterceptor';

const CustomerService = {};

CustomerService.getAll = function (params) {
  let url = `companies?`;

  if (params?.perPage) {
    url += `size=${params?.perPage}&`;
  }
  if (params?.currPage) {
    url += `page=${params.currPage}&`;
  }
  if (params?.search) {
    url += `search=${params.search}&`;
  }
  if (params?.sort_by) {
    url += `sort_by=${params.sort_by}&`;
  }
  if (params?.sort_order) {
    url += `sort_order=${params.sort_order}&`;
  }
  return fetch({
    url: url,
    method: 'get',
  });
};

CustomerService.details = function (id) {
  return fetch({
    url: `companies/${id}`,
    method: 'get',
  });
};

CustomerService.update = function (data) {
  return fetch({
    url: `companies/${data.id}`,
    method: 'patch',
    data: data,
  });
};

CustomerService.create = function (data) {
  return fetch({
    url: `companies`,
    method: 'post',
    data: data,
  });
};

export default CustomerService;
