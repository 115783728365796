import fetch from "auth/FetchInterceptor";

const DocumentsService = {};

DocumentsService.getAll = function (data) {
  return fetch({
    url: "vehicles",
    method: "get",
  });
};

DocumentsService.details = function (id) {
  return fetch({
    url: `documents/ref/${id}`,
    method: "get",
  });
};

DocumentsService.update = function (data) {
  return fetch({
    url: `documents/patch/bulk`,
    method: "patch",
    data: data,
  });
};
export default DocumentsService;
