import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import FirebaseService from 'services/FirebaseService';
import VehicleService from 'services/VehicleService';

export const initialState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  token: localStorage.getItem(AUTH_TOKEN) || null,
  data: null,
  details: null,
  availableVehicle: null,
};

export const getAllVehicle = createAsyncThunk(
  'vehicle/getAll',
  async (params, { rejectWithValue }) => {
    try {
      const response = await VehicleService.getAll(params);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const getAllAvailableVehicle = createAsyncThunk(
  'vehicle/getAvailableAll',
  async (params, { rejectWithValue }) => {
    try {
      const response = await VehicleService.getAvailableAll(params);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const getVehicleDetails = createAsyncThunk(
  'vehicle/getDetails',
  async (id, { rejectWithValue }) => {
    try {
      const response = await VehicleService.details(id);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const updateVehicleDetails = createAsyncThunk(
  'vehicle/updateDetails',
  async (data, { rejectWithValue }) => {
    try {
      const response = await VehicleService.update(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const vehicleSlice = createSlice({
  name: 'vehicle',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    getAllVehicleSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllVehicle.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllVehicle.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getAllVehicle.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.data = null;
      })
      .addCase(getAllAvailableVehicle.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllAvailableVehicle.fulfilled, (state, action) => {
        state.loading = false;
        state.availableVehicle = action.payload;
      })
      .addCase(getAllAvailableVehicle.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.availableVehicle = null;
      })
      .addCase(getVehicleDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVehicleDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.details = action.payload;
      })
      .addCase(getVehicleDetails.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.details = null;
      })
      .addCase(updateVehicleDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateVehicleDetails.fulfilled, (state, action) => {
        state.loading = false;
        // state.details = action.payload;
      })
      .addCase(updateVehicleDetails.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      });
  },
});

export const { getAllVehicleSuccess, showLoading } = vehicleSlice.actions;

export default vehicleSlice.reducer;
