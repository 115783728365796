import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_TOKEN } from "constants/AuthConstant";
import DriverService from "services/DriverService";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  token: localStorage.getItem(AUTH_TOKEN) || null,
  data: null,
};

export const getAllDriver = createAsyncThunk(
  "driver/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await DriverService.getAll();
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const updateDriver = createAsyncThunk(
  "driver/update",
  async (data, { rejectWithValue }) => {
    try {
      const response = await DriverService.update(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const driverSlice = createSlice({
  name: "driver",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    getAllDriverSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDriver.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllDriver.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getAllDriver.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.data = null;
      })
      .addCase(updateDriver.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateDriver.fulfilled, (state, action) => {
        state.loading = false;
        // state.data = action.payload;
      })
      .addCase(updateDriver.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        // state.data = null;
      });
  },
});

export const { getAllDriverSuccess, showLoading } = driverSlice.actions;

export default driverSlice.reducer;
