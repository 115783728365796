import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_TOKEN } from "constants/AuthConstant";
import BrokerService from "services/BrokerService";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  token: localStorage.getItem(AUTH_TOKEN) || null,
  data: null,
  details: null,
};

export const getAllBroker = createAsyncThunk(
  "broker/getAll",
  async (params, { rejectWithValue }) => {
    try {
      const response = await BrokerService.getAll(params);
      return response;
    } catch (err) {
      console.log("Error", err);
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getBrokerDetails = createAsyncThunk(
  "broker/getDetails",
  async (id, { rejectWithValue }) => {
    try {
      console.log("id", id);
      const response = await BrokerService.details(id);
      return response;
    } catch (err) {
      console.log("Error", err);
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const updateBrokerDetails = createAsyncThunk(
  "broker/updateDetails",
  async (data, { rejectWithValue }) => {
    try {
      const response = await BrokerService.update(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const brokerSlice = createSlice({
  name: "broker",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    getAllBrokerSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBroker.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllBroker.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getAllBroker.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.data = null;
      })
      .addCase(getBrokerDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBrokerDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.details = action.payload;
      })
      .addCase(getBrokerDetails.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.details = null;
      })
      .addCase(updateBrokerDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateBrokerDetails.fulfilled, (state, action) => {
        state.loading = false;
        // state.details = action.payload;
      })
      .addCase(updateBrokerDetails.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        // state.details = null;
      });
  },
});

export const { getAllBrokerSuccess, showLoading } = brokerSlice.actions;

export default brokerSlice.reducer;
