import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import FirebaseService from 'services/FirebaseService';
import LoadService from 'services/LoadService';

export const initialState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  token: localStorage.getItem(AUTH_TOKEN) || null,
  data: null,
  details: null,
  availableLoad: null,
};

export const getAllLoad = createAsyncThunk(
  'load/getAll',
  async (params, { rejectWithValue }) => {
    try {
      const response = await LoadService.getAll(params);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const getAllAvailableLoad = createAsyncThunk(
  'load/getAvailableAll',
  async (params, { rejectWithValue }) => {
    try {
      const response = await LoadService.getAvailableAll(params);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const getLoadDetails = createAsyncThunk(
  'load/getDetails',
  async (id, { rejectWithValue }) => {
    try {
      console.log('id', id);
      const response = await LoadService.details(id);
      return response;
    } catch (err) {
      console.log('Error', err);
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const updateLoadDetails = createAsyncThunk(
  'load/updateDetails',
  async (data, { rejectWithValue }) => {
    try {
      console.log('data', data);
      const response = await LoadService.update(data);
      return response;
    } catch (err) {
      console.log('Error', err);
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const loadSlice = createSlice({
  name: 'load',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    getAllLoadSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllLoad.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllLoad.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getAllLoad.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.data = null;
      })
      .addCase(getAllAvailableLoad.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllAvailableLoad.fulfilled, (state, action) => {
        state.loading = false;
        state.availableLoad = action.payload;
      })
      .addCase(getAllAvailableLoad.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.availableLoad = null;
      })
      .addCase(getLoadDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLoadDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.details = action.payload;
      })
      .addCase(getLoadDetails.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.details = null;
      })
      .addCase(updateLoadDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateLoadDetails.fulfilled, (state, action) => {
        state.loading = false;
        // state.details = action.payload;
      })
      .addCase(updateLoadDetails.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      });
  },
});

export const { getAllLoadSuccess, showLoading } = loadSlice.actions;

export default loadSlice.reducer;
