import fetch from "auth/FetchInterceptor";

const AuthService = {};

AuthService.login = function (data) {
  return fetch({
    url: "users/auth",
    method: "post",
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

AuthService.getMe = function () {
  return fetch({
    url: "users/me",
    method: "get",
  });
};

AuthService.register = function (data) {
  return fetch({
    url: "/auth/register",
    method: "post",
    data: data,
  });
};

AuthService.logout = function () {
  return fetch({
    url: "/auth/logout",
    method: "post",
  });
};

AuthService.loginInOAuth = function () {
  return fetch({
    url: "/auth/loginInOAuth",
    method: "post",
  });
};

AuthService.vehicle = function () {
  return fetch({
    url: "v1/vehicles",
    method: "get",
  });
};

AuthService.broker = function () {
  return fetch({
    url: "v1/owners",
    method: "get",
  });
};

AuthService.owner = function () {
  return fetch({
    url: "v1/brokers",
    method: "get",
  });
};
export default AuthService;
