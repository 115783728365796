import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import LoadAdvanceService from 'services/LoadAdvanceService';

export const initialState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  token: localStorage.getItem(AUTH_TOKEN) || null,
  data: null,
  details: null,
  detailsForTrip: null,
};

export const getAllLoadAdvances = createAsyncThunk(
  'loadAdvance/getAll',
  async (_, { rejectWithValue }) => {
    try {
      const response = await LoadAdvanceService.getAll();
      return response;
    } catch (err) {
      console.log('Error', err);
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const getLoadAdvanceDetails = createAsyncThunk(
  'loadAdvance/getDetails',
  async (id, { rejectWithValue }) => {
    try {
      console.log('id', id);
      const response = await LoadAdvanceService.details(id);
      return response;
    } catch (err) {
      console.log('Error', err);
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const getLoadAdvanceDetailsForTrip = createAsyncThunk(
  'loadAdvance/getDetailsForTrip',
  async (trip_id, { rejectWithValue }) => {
    try {
      console.log('trip_id', trip_id);
      const response = await LoadAdvanceService.detailsForTrip(trip_id);
      return response;
    } catch (err) {
      console.log('Error', err);
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const updateLoadAdvanceDetails = createAsyncThunk(
  'loadAdvance/updateDetails',
  async (data, { rejectWithValue }) => {
    try {
      const response = await LoadAdvanceService.update(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const addLoadAdvance = createAsyncThunk(
  'loadAdvance/add',
  async (data, { rejectWithValue }) => {
    try {
      const response = await LoadAdvanceService.create(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const deleteLoadAdvance = createAsyncThunk(
  'loadAdvance/delete',
  async (id, { rejectWithValue }) => {
    try {
      const response = await LoadAdvanceService.delete(id);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const loadAdvanceSlice = createSlice({
  name: 'loadAdvance',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    getAllLoadAdvancesSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllLoadAdvances.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllLoadAdvances.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getAllLoadAdvances.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.data = null;
      })
      .addCase(getLoadAdvanceDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLoadAdvanceDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.details = action.payload;
      })
      .addCase(getLoadAdvanceDetails.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.details = null;
      })
      .addCase(getLoadAdvanceDetailsForTrip.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLoadAdvanceDetailsForTrip.fulfilled, (state, action) => {
        state.loading = false;
        state.detailsForTrip = action.payload;
      })
      .addCase(getLoadAdvanceDetailsForTrip.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.detailsForTrip = null;
      })
      .addCase(updateLoadAdvanceDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateLoadAdvanceDetails.fulfilled, (state, action) => {
        state.loading = false;
        // state.details = action.payload;
      })
      .addCase(updateLoadAdvanceDetails.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        // state.details = null;
      })
      .addCase(addLoadAdvance.pending, (state) => {
        state.loading = true;
      })
      .addCase(addLoadAdvance.fulfilled, (state, action) => {
        state.loading = false;
        // state.details = action.payload;
      })
      .addCase(addLoadAdvance.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        // state.details = null;
      })
      .addCase(deleteLoadAdvance.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteLoadAdvance.fulfilled, (state, action) => {
        state.loading = false;
        // state.details = action.payload;
      })
      .addCase(deleteLoadAdvance.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        // state.details = null;
      });
  },
});

export const { getAllAdvancesSuccess, showLoading } = loadAdvanceSlice.actions;

export default loadAdvanceSlice.reducer;
