import fetch from "auth/FetchInterceptor";

const VehicleLengthService = {};

VehicleLengthService.getAll = function () {
  return fetch({
    url: "vehicleLengths",
    method: "get",
  });
};

export default VehicleLengthService;
