import fetch from "auth/FetchInterceptor";

const BodyTypeService = {};

BodyTypeService.getAll = function () {
  return fetch({
    url: "bodyTypes",
    method: "get",
  });
};

export default BodyTypeService;
