import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import CityService from 'services/CityService';

export const initialState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  token: localStorage.getItem(AUTH_TOKEN) || null,
  data: null,
};

export const getAllCity = createAsyncThunk(
  'city/getAll',
  async (params, { rejectWithValue }) => {
    try {
      const response = await CityService.getAll(params);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const citySlice = createSlice({
  name: 'city',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    getAllCitySuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCity.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCity.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getAllCity.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.data = null;
      });
  },
});

export const { getAllCitySuccess, showLoading } = citySlice.actions;

export default citySlice.reducer;
