import fetch from "auth/FetchInterceptor";

const RTOService = {};

RTOService.getAll = function () {
  return fetch({
    url: "rtos",
    method: "get",
  });
};

export default RTOService;
