import fetch from "auth/FetchInterceptor";

const DriverService = {};

DriverService.getAll = function () {
  return fetch({
    url: `drivers`,
    method: "get",
  });
};

DriverService.update = function (data) {
  return fetch({
    url: `drivers/${data.id}`,
    method: "patch",
    data: data,
  });
};

export default DriverService;
