import fetch from "auth/FetchInterceptor";

const DisputeService = {};

DisputeService.getAll = function () {
  return fetch({
    url: "disputes",
    method: "get",
  });
};

DisputeService.details = function (id) {
  return fetch({
    url: `disputes/${id}`,
    method: "get",
  });
};

DisputeService.update = function (data) {
  return fetch({
    url: `disputes/${data.dispute_id}`,
    method: "patch",
    data: data,
  });
};

DisputeService.create = function (data) {
  return fetch({
    url: `disputes`,
    method: "post",
    data: data,
  });
};

DisputeService.detailsForTrip = function (trip_id) {
  return fetch({
    url: `disputes/trip/${trip_id}`,
    method: "get",
  });
};

DisputeService.delete = function (id) {
  return fetch({
    url: `disputes/${id}`,
    method: "delete",
  });
};

export default DisputeService;
