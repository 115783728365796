import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_TOKEN } from "constants/AuthConstant";
import VehicleLengthService from "services/VehicleLengthService";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  token: localStorage.getItem(AUTH_TOKEN) || null,
  data: null,
};

export const getAllVehicleLength = createAsyncThunk(
  "vehicleLength/getAll",
  async (state_id, { rejectWithValue }) => {
    try {
      const response = await VehicleLengthService.getAll(state_id);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const vehicleLengthSlice = createSlice({
  name: "vehicleLength",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    getAllVehicleLengthSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllVehicleLength.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllVehicleLength.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getAllVehicleLength.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.data = null;
      });
  },
});

export const { getAllVehicleLengthSuccess, showLoading } =
  vehicleLengthSlice.actions;

export default vehicleLengthSlice.reducer;
