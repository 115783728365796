import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_TOKEN } from "constants/AuthConstant";
import CustomerService from "services/CustomerService";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  token: localStorage.getItem(AUTH_TOKEN) || null,
  data: null,
  details: null,
};

export const getAllCustomer = createAsyncThunk(
  "customer/getAll",
  async (params, { rejectWithValue }) => {
    try {
      const response = await CustomerService.getAll(params);
      return response;
    } catch (err) {
      console.log("Error", err);
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getCustomerDetails = createAsyncThunk(
  "customer/getDetails",
  async (id, { rejectWithValue }) => {
    try {
      console.log("id", id);
      const response = await CustomerService.details(id);
      return response;
    } catch (err) {
      console.log("Error", err);
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const updateCustomerDetails = createAsyncThunk(
  "customer/updateDetails",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CustomerService.update(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const addCustomer = createAsyncThunk(
  "customer/add",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CustomerService.create(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    getAllCustomerSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getAllCustomer.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.data = null;
      })
      .addCase(getCustomerDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCustomerDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.details = action.payload;
      })
      .addCase(getCustomerDetails.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.details = null;
      })
      .addCase(updateCustomerDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCustomerDetails.fulfilled, (state, action) => {
        state.loading = false;
        // state.details = action.payload;
      })
      .addCase(updateCustomerDetails.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        // state.details = null;
      })
      .addCase(addCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(addCustomer.fulfilled, (state, action) => {
        state.loading = false;
        // state.details = action.payload;
      })
      .addCase(addCustomer.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        // state.details = null;
      });
  },
});

export const { getAllCustomerSuccess, showLoading } = customerSlice.actions;

export default customerSlice.reducer;
