import fetch from "auth/FetchInterceptor";

const AccountsService = {};

AccountsService.getAll = function (data) {
  return fetch({
    url: "accounts",
    method: "get",
  });
};

AccountsService.details = function (id) {
  return fetch({
    url: `accounts/ref/${id}`,
    method: "get",
  });
};

AccountsService.update = function (data) {
  return fetch({
    url: `accounts/${data.account_id}`,
    method: "patch",
    data: data,
  });
};

export default AccountsService;
