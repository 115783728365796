import fetch from 'auth/FetchInterceptor';

const BrokerService = {};

BrokerService.getAll = function (params) {
  let url = `brokers?`;
  if (params?.perPage) {
    url += `size=${params?.perPage}&`;
  }
  if (params?.currPage) {
    url += `page=${params.currPage}&`;
  }
  if (params?.search) {
    url += `search=${params.search}&`;
  }
  if (params?.sort_by) {
    url += `sort_by=${params.sort_by}&`;
  }
  if (params?.sort_order) {
    url += `sort_order=${params.sort_order}`;
  }
  return fetch({
    url: url,
    method: 'get',
  });
};

BrokerService.details = function (id) {
  return fetch({
    url: `brokers/${id}`,
    method: 'get',
  });
};

BrokerService.update = function (data) {
  return fetch({
    url: `brokers/${data.broker_id}`,
    method: 'patch',
    data: data,
  });
};

export default BrokerService;
