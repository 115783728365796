import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_TOKEN } from "constants/AuthConstant";
import RTOService from "services/RTOService";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  token: localStorage.getItem(AUTH_TOKEN) || null,
  data: null,
};

export const getAllRTO = createAsyncThunk(
  "rto/getAll",
  async (state_id, { rejectWithValue }) => {
    try {
      const response = await RTOService.getAll(state_id);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const rtoSlice = createSlice({
  name: "rto",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    getAllRTOSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllRTO.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllRTO.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getAllRTO.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.data = null;
      });
  },
});

export const { getAllRTOSuccess, showLoading } = rtoSlice.actions;

export default rtoSlice.reducer;
