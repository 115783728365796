import fetch from 'auth/FetchInterceptor';

const OwnerService = {};

OwnerService.getAll = function (params) {
  let url = `owners?`;

  if (params?.perPage) {
    url += `size=${params?.perPage}&`;
  }
  if (params?.currPage) {
    url += `page=${params.currPage}&`;
  }
  if (params?.search) {
    url += `search=${params.search}&`;
  }
  if (params?.sort_by) {
    url += `sort_by=${params.sort_by}&`;
  }
  if (params?.sort_order) {
    url += `sort_order=${params.sort_order}&`;
  }
  return fetch({
    url: url,
    method: 'get',
  });
};

OwnerService.details = function (id) {
  return fetch({
    url: `owners/${id}`,
    method: 'get',
  });
};

OwnerService.update = function (data) {
  return fetch({
    url: `owners/${data.owner_id}`,
    method: 'patch',
    data: data,
  });
};

export default OwnerService;
