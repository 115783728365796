import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_TOKEN } from "constants/AuthConstant";
import AdvanceService from "services/AdvanceService";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  token: localStorage.getItem(AUTH_TOKEN) || null,
  data: null,
  details: null,
  detailsForTrip: null,
};

export const getAllAdvances = createAsyncThunk(
  "advance/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AdvanceService.getAll();
      return response;
    } catch (err) {
      console.log("Error", err);
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getAdvanceDetails = createAsyncThunk(
  "advance/getDetails",
  async (id, { rejectWithValue }) => {
    try {
      console.log("id", id);
      const response = await AdvanceService.details(id);
      return response;
    } catch (err) {
      console.log("Error", err);
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getAdvanceDetailsForTrip = createAsyncThunk(
  "advance/getDetailsForTrip",
  async (trip_id, { rejectWithValue }) => {
    try {
      console.log("trip_id", trip_id);
      const response = await AdvanceService.detailsForTrip(trip_id);
      return response;
    } catch (err) {
      console.log("Error", err);
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const updateAdvanceDetails = createAsyncThunk(
  "advance/updateDetails",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AdvanceService.update(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const addAdvance = createAsyncThunk(
  "advance/add",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AdvanceService.create(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const deleteAdvance = createAsyncThunk(
  "advance/delete",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AdvanceService.delete(id);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const advanceSlice = createSlice({
  name: "advance",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    getAllAdvancesSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllAdvances.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllAdvances.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getAllAdvances.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.data = null;
      })
      .addCase(getAdvanceDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAdvanceDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.details = action.payload;
      })
      .addCase(getAdvanceDetails.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.details = null;
      })
      .addCase(getAdvanceDetailsForTrip.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAdvanceDetailsForTrip.fulfilled, (state, action) => {
        state.loading = false;
        state.detailsForTrip = action.payload;
      })
      .addCase(getAdvanceDetailsForTrip.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.detailsForTrip = null;
      })
      .addCase(updateAdvanceDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAdvanceDetails.fulfilled, (state, action) => {
        state.loading = false;
        // state.details = action.payload;
      })
      .addCase(updateAdvanceDetails.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        // state.details = null;
      })
      .addCase(addAdvance.pending, (state) => {
        state.loading = true;
      })
      .addCase(addAdvance.fulfilled, (state, action) => {
        state.loading = false;
        // state.details = action.payload;
      })
      .addCase(addAdvance.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        // state.details = null;
      })
      .addCase(deleteAdvance.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteAdvance.fulfilled, (state, action) => {
        state.loading = false;
        // state.details = action.payload;
      })
      .addCase(deleteAdvance.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        // state.details = null;
      });
  },
});

export const { getAllAdvancesSuccess, showLoading } = advanceSlice.actions;

export default advanceSlice.reducer;
