import fetch from 'auth/FetchInterceptor';

const LoadService = {};

LoadService.getAll = function (params) {
  let url = `loads/all?used=true&expired=true&`;

  if (params?.perPage) {
    url += `size=${params?.perPage}&`;
  }
  if (params?.currPage) {
    url += `page=${params.currPage}&`;
  }
  if (params?.search) {
    url += `search=${params.search}&`;
  }
  if (params?.sort_by) {
    url += `sort_by=${params.sort_by}&`;
  }
  if (params?.sort_order) {
    url += `sort_order=${params.sort_order}&`;
  }
  return fetch({
    url: url,
    method: 'get',
  });
};

LoadService.getAvailableAll = function (params) {
  return fetch({
    url: `loads?size=${params?.perPage}&page=${params.currPage}&search=${params.search}`,
    method: 'get',
  });
};

LoadService.details = function (id) {
  return fetch({
    url: `loads/${id}`,
    method: 'get',
  });
};

LoadService.update = function (data) {
  return fetch({
    url: `loads/${data.load_id}`,
    method: 'patch',
    data: data,
  });
};
export default LoadService;
