import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import LoadDisputeService from 'services/LoadDisputeService';

export const initialState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  token: localStorage.getItem(AUTH_TOKEN) || null,
  data: null,
  details: null,
  detailsForTrip: null,
};

export const getAllLoadDispute = createAsyncThunk(
  'loadDisputes/getAll',
  async (_, { rejectWithValue }) => {
    try {
      const response = await LoadDisputeService.getAll();
      return response;
    } catch (err) {
      console.log('Error', err);
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const getLoadDisputeDetails = createAsyncThunk(
  'loadDisputes/getDetails',
  async (id, { rejectWithValue }) => {
    try {
      console.log('id', id);
      const response = await LoadDisputeService.details(id);
      return response;
    } catch (err) {
      console.log('Error', err);
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const getLoadDisputeDetailsForTrip = createAsyncThunk(
  'loadDisputes/getDetailsForTrip',
  async (trip_id, { rejectWithValue }) => {
    try {
      console.log('trip_id', trip_id);
      const response = await LoadDisputeService.detailsForTrip(trip_id);
      return response;
    } catch (err) {
      console.log('Error', err);
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const updateLoadDisputeDetails = createAsyncThunk(
  'loadDisputes/updateDetails',
  async (data, { rejectWithValue }) => {
    try {
      const response = await LoadDisputeService.update(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const addLoadDispute = createAsyncThunk(
  'loadDisputes/add',
  async (data, { rejectWithValue }) => {
    try {
      const response = await LoadDisputeService.create(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const deleteLoadDispute = createAsyncThunk(
  'loadDisputes/delete',
  async (id, { rejectWithValue }) => {
    try {
      const response = await LoadDisputeService.delete(id);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const loadDisputesSlice = createSlice({
  name: 'loadDisputes',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    getAllLoadDisputeSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllLoadDispute.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllLoadDispute.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getAllLoadDispute.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.data = null;
      })
      .addCase(getLoadDisputeDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLoadDisputeDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.details = action.payload;
      })
      .addCase(getLoadDisputeDetails.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.details = null;
      })
      .addCase(getLoadDisputeDetailsForTrip.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLoadDisputeDetailsForTrip.fulfilled, (state, action) => {
        state.loading = false;
        state.detailsForTrip = action.payload;
      })
      .addCase(getLoadDisputeDetailsForTrip.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.detailsForTrip = null;
      })
      .addCase(updateLoadDisputeDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateLoadDisputeDetails.fulfilled, (state, action) => {
        state.loading = false;
        // state.details = action.payload;
      })
      .addCase(updateLoadDisputeDetails.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        // state.details = null;
      })
      .addCase(addLoadDispute.pending, (state) => {
        state.loading = true;
      })
      .addCase(addLoadDispute.fulfilled, (state, action) => {
        state.loading = false;
        // state.details = action.payload;
      })
      .addCase(addLoadDispute.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        // state.details = null;
      })
      .addCase(deleteLoadDispute.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteLoadDispute.fulfilled, (state, action) => {
        state.loading = false;
        // state.details = action.payload;
      })
      .addCase(deleteLoadDispute.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        // state.details = null;
      });
  },
});

export const { getAllLoadDisputeSuccess, showLoading } =
  loadDisputesSlice.actions;

export default loadDisputesSlice.reducer;
