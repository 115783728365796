import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_TOKEN } from "constants/AuthConstant";
import FirebaseService from "services/FirebaseService";
import DestinationService from "services/DestinationService";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  token: localStorage.getItem(AUTH_TOKEN) || null,
  data: null,
  vehicleDestination: null,
};

export const getAllDestination = createAsyncThunk(
  "destination/getAll",
  async (_, { rejectWithValue }) => {
    try {
      console.log("getAll Called");
      const response = await DestinationService.getAll();
      return response;
    } catch (err) {
      console.log("Error", err);
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getVehicleDestination = createAsyncThunk(
  "destination/getVehicleDestination",
  async (id, { rejectWithValue }) => {
    try {
      console.log("id", id);
      const response = await DestinationService.details(id);
      return response;
    } catch (err) {
      console.log("Error", err);
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const updateVehicleDestination = createAsyncThunk(
  "destination/updateVehicleDestination",
  async (data, { rejectWithValue }) => {
    try {
      const response = await DestinationService.update(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const destinationSlice = createSlice({
  name: "destination",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    getAllDestinationSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDestination.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllDestination.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getAllDestination.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.data = null;
      })
      .addCase(getVehicleDestination.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVehicleDestination.fulfilled, (state, action) => {
        state.loading = false;
        state.vehicleDestination = action.payload;
      })
      .addCase(getVehicleDestination.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.vehicleDestination = null;
      })
      .addCase(updateVehicleDestination.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateVehicleDestination.fulfilled, (state, action) => {
        state.loading = false;
        // state.vehicleDestination = action.payload;
      })
      .addCase(updateVehicleDestination.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        // state.vehicleDestination = null;
      });
  },
});

export const { getAllDestinationSuccess, showLoading } =
  destinationSlice.actions;

export default destinationSlice.reducer;
