import fetch from 'auth/FetchInterceptor';

const VehicleService = {};

VehicleService.getAll = function (params) {
  let url = `vehicles/all?used=true&`;

  if (params?.perPage) {
    url += `size=${params?.perPage}&`;
  }
  if (params?.currPage) {
    url += `page=${params.currPage}&`;
  }
  if (params?.search) {
    url += `search=${params.search}&`;
  }
  if (params?.sort_by) {
    url += `sort_by=${params.sort_by}&`;
  }
  if (params?.sort_order) {
    url += `sort_order=${params.sort_order}&`;
  }
  return fetch({
    url: url,
    method: 'get',
  });
};

VehicleService.getAvailableAll = function (params) {
  return fetch({
    url: `vehicles?size=${params?.perPage}&page=${params.currPage}&search=${params.search}`,
    method: 'get',
  });
};

VehicleService.details = function (id) {
  return fetch({
    url: `vehicles/${id}`,
    method: 'get',
  });
};

VehicleService.update = function (data) {
  return fetch({
    url: `vehicles/${data.vehicle_id}`,
    method: 'patch',
    data: data,
  });
};
export default VehicleService;
