import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_TOKEN } from "constants/AuthConstant";
import DisputeService from "services/DisputeService";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  token: localStorage.getItem(AUTH_TOKEN) || null,
  data: null,
  details: null,
  detailsForTrip: null,
};

export const getAllDispute = createAsyncThunk(
  "disputes/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await DisputeService.getAll();
      return response;
    } catch (err) {
      console.log("Error", err);
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getDisputeDetails = createAsyncThunk(
  "disputes/getDetails",
  async (id, { rejectWithValue }) => {
    try {
      console.log("id", id);
      const response = await DisputeService.details(id);
      return response;
    } catch (err) {
      console.log("Error", err);
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getDisputeDetailsForTrip = createAsyncThunk(
  "disputes/getDetailsForTrip",
  async (trip_id, { rejectWithValue }) => {
    try {
      console.log("trip_id", trip_id);
      const response = await DisputeService.detailsForTrip(trip_id);
      return response;
    } catch (err) {
      console.log("Error", err);
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const updateDisputeDetails = createAsyncThunk(
  "disputes/updateDetails",
  async (data, { rejectWithValue }) => {
    try {
      const response = await DisputeService.update(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const addDispute = createAsyncThunk(
  "disputes/add",
  async (data, { rejectWithValue }) => {
    try {
      const response = await DisputeService.create(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const deleteDispute = createAsyncThunk(
  "disputes/delete",
  async (id, { rejectWithValue }) => {
    try {
      const response = await DisputeService.delete(id);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const disputesSlice = createSlice({
  name: "disputes",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    getAllDisputeSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDispute.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllDispute.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getAllDispute.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.data = null;
      })
      .addCase(getDisputeDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDisputeDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.details = action.payload;
      })
      .addCase(getDisputeDetails.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.details = null;
      })
      .addCase(getDisputeDetailsForTrip.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDisputeDetailsForTrip.fulfilled, (state, action) => {
        state.loading = false;
        state.detailsForTrip = action.payload;
      })
      .addCase(getDisputeDetailsForTrip.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.detailsForTrip = null;
      })
      .addCase(updateDisputeDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateDisputeDetails.fulfilled, (state, action) => {
        state.loading = false;
        // state.details = action.payload;
      })
      .addCase(updateDisputeDetails.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        // state.details = null;
      })
      .addCase(addDispute.pending, (state) => {
        state.loading = true;
      })
      .addCase(addDispute.fulfilled, (state, action) => {
        state.loading = false;
        // state.details = action.payload;
      })
      .addCase(addDispute.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        // state.details = null;
      })
      .addCase(deleteDispute.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteDispute.fulfilled, (state, action) => {
        state.loading = false;
        // state.details = action.payload;
      })
      .addCase(deleteDispute.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        // state.details = null;
      });
  },
});

export const { getAllDisputeSuccess, showLoading } = disputesSlice.actions;

export default disputesSlice.reducer;
