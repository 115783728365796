import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_TOKEN } from "constants/AuthConstant";
import AccountsService from "services/AccountsService";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  token: localStorage.getItem(AUTH_TOKEN) || null,
  data: null,
  account: null,
};

export const getAllAccounts = createAsyncThunk(
  "account/getAll",
  async (_, { rejectWithValue }) => {
    try {
      console.log("getAll Called");
      const response = await AccountsService.getAll();
      return response;
    } catch (err) {
      console.log("Error", err);
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getAccountByRef = createAsyncThunk(
  "account/getAccountDetails",
  async (id, { rejectWithValue }) => {
    try {
      console.log("id", id);
      const response = await AccountsService.details(id);
      return response;
    } catch (err) {
      console.log("Error", err);
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const updateAccount = createAsyncThunk(
  "account/updateDetails",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AccountsService.update(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    getAllAccountsSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllAccounts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllAccounts.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.account = null;
      })
      .addCase(getAllAccounts.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getAccountByRef.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAccountByRef.fulfilled, (state, action) => {
        state.loading = false;
        state.account = action.payload;
      })
      .addCase(getAccountByRef.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.account = null;
      })
      .addCase(updateAccount.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAccount.fulfilled, (state, action) => {
        state.loading = false;
        // state.account = action.payload;
      })
      .addCase(updateAccount.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        // state.account = null;
      });
  },
});

export const { getAllAccountsSuccess, showLoading } = accountsSlice.actions;

export default accountsSlice.reducer;
